import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../consts";
import axios from "axios";
import { useEffect, useState } from "react";

export default function ChargeCheckout() {
      const [loading, setLoading] = useState(false);
      const btnStyle = "bg-red-950 text-white p-3 rounded";
      
      
  const onSubscribe = () => {
      const payload = {
        amount: 10,
        currency: "SAR",
        customer_initiated: true,
        threeDSecure: true,
        save_card: true,
        customer: {
          first_name: "ahmed",
          last_name: "karam",
          email: "ahmedkaramdev@gmail.com",
          phone: {
            country_code: 966,
            number: 564656085,
          },
        },
      };
      if (loading) return;
      setLoading(true);
      const response = axios.post(BASE_URL + "/tap/charge", payload);
      response
        .then((res) => {
          console.log(res);
          const { data } = res?.data || {};
          window.location.href = data.transaction.url;
        })
        .catch((err) => {
          setLoading(false);
          alert("Error: ", err);
        });
    };
  

      return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
            <div className="flex flex-col items-center gap-5">
          <strong className="text-2xl">Welcome to the Charge Flow</strong>
          <p>
            First we want you to subscribe for our service it costs only{" "}
            <strong>10 SAR</strong>.{" "}
          </p>
          <p>
            You will pay using the checkout page, then you will enter your OTP to complete the payment. And we won't save your card.
          </p>
        </div>
        <div className="flex justify-center mt-8">
              <button onClick={onSubscribe} className={btnStyle}>
                {loading ? <p>Loading...</p> : <p>Subscribe</p>}
              </button>
            </div>
            </div>
            </div>
      )
}