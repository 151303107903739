import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BASE_URL } from "../consts";

export default function PaymentStatus() {
  const [loading, setLoading] = useState(true);
  const [charge, setCharge] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const CHARGE_url = BASE_URL + "/tap/charge/";
  useEffect(() => {
    const charge_id = searchParams.get("tap_id");
    const response = axios.get(CHARGE_url + charge_id);
    response
      .then((res) => {
        const { data } = res.data || {};
        setCharge(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <p>Payment Status: {charge.status}</p>
            <p>
            {charge.status == "FAILED" && (

<p className="text-red-500">Reason: {charge.response?.message}</p>

)}
              Payment Amount: {charge.amount} {charge.currency}
            </p>
            <p>Payment ID: {charge.charge_id || charge.id }</p>

           

           
          </div>
        )}
      </div>
      <a
              href="/"
              className="mt-5 px-3 py-2 text-white rounded-md text-center bg-blue-500"
            >
              Back to Main
            </a>
    </div>
  );
}
