import {TapCard, create, updateCurrency, reset}  from '@tap-payments/card-elements-v1'
import { useState } from 'react';

export default function Payment() {
      const [message, setMessage] = useState('')
	const [tokenCreated, setTokenCreated] = useState(false)

      return (
            <div style={{ textAlign: 'center' }}>
            <div>
                  <TapCard
                        onError={(message) => {
                              setMessage(message)
                        }}
                        onBin={(data) => {
                              console.log('onBin', data)
                        }}
                        onTokenCreated={(token) => {
                              setTokenCreated(token.id)
                              setMessage('')
                              console.log('onTokenCreated', token)
                        }}
                        publicKey='pk_test_EtHFV4BuPQokJT6jiROls87Y'
                        style={{
                              base: {
                                    color: '#535353',
                                    lineHeight: '18px',
                                    fontFamily: 'sans-serif',
                                    fontSmoothing: 'antialiased',
                                    fontSize: '16px',
                                    '::placeholder': {
                                          color: 'rgba(0, 0, 0, 0.26)',
                                          fontSize: '15px'
                                    }
                              },
                              invalid: {
                                    color: 'red'
                              }
                        }}
                        paymentOptions={{
                              currencyCode: 'all',
                              labels: {
                                    cardNumber: 'Card Number',
                                    expirationDate: 'MM/YY',
                                    cvv: 'CVV',
                                    cardHolder: 'Card Holder Name'
                              },
                              TextDirection: 'ltr'
                        }}
                  />
            </div>
            {tokenCreated != false && <div>Token: {tokenCreated}</div>}
            {message != '' && <div>Error: {message}</div>}

            <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                  <button className='button token_button' onClick={create}>
                        Create Token
                  </button>
            </div>
      </div>
      )
}