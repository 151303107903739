import Product from "../imgs/product.svg"

export default function Card({ price }) {
  return (
    <section id="single-product" className="w-80 flex flex-col gap-2">
      <div className="bg-blue-500 h-80 w-80 rounded flex justify-center">
            <img src={Product} width={128} />
      </div>
      <div className="flex justify-between">
        <p className="font-semibold text-2xl">Item</p>
        <p>Price: {price || 0}</p>
      </div>
      <button className="w-full rounded p-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white"><a href="/cart">Buy</a></button>
    </section>
  );
}
