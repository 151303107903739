import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../consts";

export default function Cart() {
      const [loading, setLoading] = useState(false);
  const CART_LIST = [
    {
      id: 1,
      name: "Product 1",
      price: 10.0,
    },
    {
      id: 2,
      name: "Product 2",
      price: 20.0,
    },
  ];

  const total = CART_LIST.reduce((acc, item) => acc + item.price, 0);

  const onCheckout = async() => {
      setLoading(true);
    const payload = {
      amount: total,
      currency: "SAR",
      customer_initiated: true,
      threeDSecure: true,
      save_card: true,
      customer: {
        first_name: "ahmed",
        middle_name: "karam",
        last_name: "youssef",
        email: "ahmedkaramdev@gmail.com",
        phone: {
          country_code: 966,
          number: 564656085,
        },
      },
    };
    const response = axios.post(BASE_URL + "/tap/charge", payload);
    response.then((res) => {
      const {data} = res || {};
      window.location.href = data.transaction.url
    }).catch((err) => {
      setLoading(false);
      alert("Error: ", err);      
    });
}
   
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-extrabold text-center text-gray-900">
          Shopping Cart
        </h2>

        <div className="space-y-2">
          {CART_LIST.map((item, i) => {
            return (
              <div className="flex justify-between" key={i}>
                <div className="flex-1">
                  <p className="font-bold">{item.name}</p>
                  <p>SAR {item.price}</p>
                </div>
                <button className="px-2 py-1 text-white bg-red-500 rounded">
                  -
                </button>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between font-bold">
          <p>Total</p>
          <p>SAR {total}</p>
        </div>
        {
              loading ? <p>Loading...</p> :   <button
              className="w-full px-3 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              onClick={onCheckout}
            >
              Checkout
            </button>
        }
      
      </div>
    </div>
  );
}
