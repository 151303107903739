import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../consts";
import { useNavigate } from "react-router-dom";

export default function AuthorizeMITCheckout() {
  const [renewSuccess, setRenewSuccess] = useState(false);
  const [renewData, setRenewData] = useState({});
  const [authorizeData, setAuthorizeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasAuthorized, setHasAuthorized] = useState(false);
  const nav = useNavigate();
  const btnStyle = "bg-red-950 text-white p-3 rounded";

  useEffect(() => {
    const url = BASE_URL + "/tap/customer/1";
    const response = axios.get(url);
    response
      .then((res) => {
        console.log(res.data);
        const { status, data } = res.data || {};
        if (status === 200) {
          if (data.card_id && data.tap_customer_id) {
            setHasAuthorized(true);
            setAuthorizeData(data);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const onSubscribe = () => {
    const payload = {
      amount: 10,
      currency: "SAR",
      customer_initiated: true,
      threeDSecure: true,
      save_card: true,
      customer: {
        first_name: "ahmed",
        last_name: "karam",
        email: "ahmedkaramdev@gmail.com",
        phone: {
          country_code: 966,
          number: 564656085,
        },
      },
    };
    if (loading) return;
    setLoading(true);
    const response = axios.post(BASE_URL + "/tap/authorize", payload);
    response
      .then((res) => {
        console.log(res);
        const { data } = res?.data || {};
        window.location.href = data.transaction.url;
      })
      .catch((err) => {
        setLoading(false);
        alert("Error: ", err);
      });
  };

  const onRenew = () => {
    const payload = {
      amount: 10,
      currency: "SAR",
      customer_initiated: true,
      threeDSecure: true,
      save_card: true,
      payment_agreement: {
        id: authorizeData.payment_agreement_id,
      },
      customer: {
        id: authorizeData.tap_customer_id,
      },
      card: {
        id: authorizeData.card_id,
      },
    };
    if (loading) return;
    setLoading(true);
    const response = axios.post(BASE_URL + "/tap/renew", payload);
    response
      .then((res) => {
        const { data } = res?.data || {};
        setRenewData(data);
        setLoading(false);
        setRenewSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        alert("Error: ", err);
      });
  };

  useEffect(() => {
    if (renewSuccess) {
      setTimeout(() => nav("/"), 5000);
    }
  }, [renewSuccess]);

  return (
    <section className="mt-12">
      {!hasAuthorized && (
        <div className="flex flex-col items-center gap-5">
          <h1 className="text-5xl">Welcome to the Authorize Flow</h1>
          <p>
            First we want you to subscribe for our service it costs only{" "}
            <strong>10 SAR</strong>.{" "}
          </p>
          <p>
            You will click on subscribe button and then you will enter your Card
            Details, then you will enter your OTP to complete the payment.
          </p>
        </div>
      )}
      {renewSuccess && (
        <section className="flex flex-col items-center gap-5">
          <h1 className="text-5xl">Renew Success</h1>
          <p>
            Your subscription has been renewed successfully, you can check the
            renewed data below.
          </p>
          <div> Renewed Authorize ID: {renewData.id}</div>
          <div> Renewed Amount: {renewData.amount}</div>
          <div> Renewed Currency: {renewData.currency}</div>
          <div> Renewed Status: {renewData.status}</div>

          <p>Redirecting to the main page in 5 seconds...</p>
        </section>
      )}

      {!renewSuccess && (
        <>
          {hasAuthorized && (
            <div className="flex flex-col items-center gap-5">
              <h1 className="text-5xl">
                We have your {authorizeData.card_scheme || "card"} that ends
                with:
                {authorizeData.card_last_four}{" "}
              </h1>
              <p>Lets renew without redirecting to the checkout page.</p>
            </div>
          )}

          {hasAuthorized ? (
            <div className="flex justify-center mt-8">
              <button onClick={onRenew} className={btnStyle}>
                {loading ? <p>Loading...</p> : <p>Renew</p>}
              </button>
            </div>
          ) : (
            <div className="flex justify-center mt-8">
              <button onClick={onSubscribe} className={btnStyle}>
                {loading ? <p>Loading...</p> : <p>Subscribe</p>}
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
}
