import Cart from "./pages/Cart";
import Products from "./pages/Products";
import Checkout from "./pages/Checkout";
import PaymentStatus from "./pages/PaymentStatus";
import Signup from "./pages/Signup";
import Main from "./pages/Main";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ChargeFlow from "./pages/MIT/ChargeFlow";
import Payment from "./pages/Payment";
import ChargeCustom from "./pages/CIT/ChargeCustom";
import ChargeCheckout from "./pages/CIT/ChargeCheckout";
import ChargeMITCustom from "./pages/MIT/ChargeMITCustom";
import AuthorizeCheckout from "./pages/CIT/AuthorizeCheckout";
import AuthorizeCustom from "./pages/CIT/AuthorizeCustom";
import AuthorizeMITCustom from "./pages/MIT/AuthorizeMITCustom";
import AuthorizeMITCheckout from "./pages/MIT/AuthorizeMITCheckout";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />

          <Route path="mit/charge" element={<ChargeFlow />} />
          <Route path="mit/charge-custom" element={<ChargeMITCustom />} />
          <Route path="mit/authorize" element={<AuthorizeMITCheckout />} />
          <Route path="mit/authorize-custom" element={<AuthorizeMITCustom />} />


          <Route path="cit/charge" element={<ChargeCheckout />} />
          <Route path="cit/charge-custom" element={<ChargeCustom />} />
          <Route path="cit/authorize" element={<AuthorizeCheckout />} />
          <Route path="cit/authorize-custom" element={<AuthorizeCustom />} />



          <Route path="/signup" element={<Signup />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/products" element={<Products />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
