import { useForm, SubmitHandler } from "react-hook-form";

export default function Signup() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
      <input placeholder="first name" {...register("customer.first_name")} />
      <input placeholder="last name" {...register("customer.last_name")} />
      <input placeholder="email" {...register("customer.email")} />
      <div className="flex">
      <input defaultValue="+966" className="w-1/4" readOnly {...register("customer.phone.country_code")} />
      <input placeholder="number" {...register("customer.phone.number")} />
      </div>
      <button type="submit" className="w-full px-3 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"> Sign Up </button>
      </form>
    </section>
  );
}
