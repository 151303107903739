export default function Checkout() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-extrabold text-center text-gray-900">
          Checkout
        </h2>
        <div className="space-y-2">
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Full Name"
          />
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Address"
          />
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="City"
          />
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Country"
          />
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Postal Code"
          />
        </div>
        <div className="flex items-start">
        <input type="checkbox" id="terms" name="terms" className="h-5 w-5 text-blue-600 rounded" />
        <label for="terms" className="ml-2 text-gray-700">I agree to the Terms and Conditions</label>
      </div>
        <div className="flex justify-between font-bold">
          <p>Total</p>
          <p>SAR 30.0</p>
          </div>
          
        <button className="w-full px-3 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700">
          Place Order
        </button>
      </div>
    </div>
  );
}
