import {
  TapCard,
  create,
  updateCurrency,
  reset,
} from "@tap-payments/card-elements-v1";
import { useEffect, useState } from "react";
import CustomLogo from "../../imgs/logo.png";
import "./cit.css";
import axios from "axios";
import { BASE_URL } from "../../consts";

export default function AuthorizeCustom() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [tokenCreated, setTokenCreated] = useState(false);
  const amount = 10;

  const onPay = () => {
    if (!loading){
      setLoading(true);
      create();
    }
 
  };

  return (
    <div className="text-center">
      <div className="w-full flex justify-center my-6">
        <div className="flex flex-col justify-center items-center gap-3">
          <img src={CustomLogo} width={128} />
          <p>
            You will pay only <strong>10 SAR</strong> as one time payment
            without saving your card.
          </p>
        </div>
      </div>
      <div>
        <TapCard
          onError={(message) => {
            setMessage(message);
          }}
          onBin={(data) => {
            console.log("onBin", data);
          }}
          onTokenCreated={(token) => {
            setTokenCreated(token.id);
            const payLoad = {
              amount: amount,
              token_id: token.id,
              customer: {
                id: "cus_TS07A3720240939l5B22602875",
                first_name: "ahmed",
                last_name: "karam",
                email: "ahmedkaramdev@gmail.com",
                phone: {
                  country_code: "966",
                  number: "564656085",
                },
              },
            };
            const response = axios.post(
              BASE_URL + "/tap/cit/authorize",
              payLoad
            );
            response
              .then(({data:res}) => {
                setLoading(false);
                if (res.data.transaction.url)
                window.location.href = res.data.transaction.url;
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
              });
            console.log("onTokenCreated", token);
          }}
          publicKey="pk_test_EtHFV4BuPQokJT6jiROls87Y"
          style={{
            base: {
              color: "#535353",
              lineHeight: "18px",
              fontFamily: "sans-serif",
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "rgba(0, 0, 0, 0.26)",
                fontSize: "15px",
              },
            },
            invalid: {
              color: "red",
            },
          }}
          paymentOptions={{
            currencyCode: ["SAR"],
            labels: {
              cardNumber: "Card Number",
              expirationDate: "MM/YY",
              cvv: "CVV",
              cardHolder: "Card Holder Name",
            },
            TextDirection: "ltr",
          }}
        />
      </div>

      <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
            
        <button
          className="border border-blue-500 w-96 px-3 py-2 rounded text-center"
          onClick={onPay}
        >
            {loading ? "Loading..." : "Pay"}
        </button>
      </div>
    </div>
  );
}
