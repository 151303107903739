import Logo from "../imgs/tap_logo.png";

export default function Main() {
  const btnStyle = "w-96 px-3 py-2 text-white rounded-md text-center";
  return (
    <div className="h-screen flex flex-col gap-10">
      
      <div className="w-full flex justify-center mt-7">
        <img src={Logo} width={64} className="rounded-full" />
      </div>
            <section className="flex flex-col items-center gap-5">
            <h2 className="text-5xl">Welcome Tap Payment Gateway</h2>
            <p className="text-lg">Choose your payment flow</p>
              </section>

<div className="flex flex-col items-center gap-4 border border-gray-300 rounded-md mx-3 p-3">
      <strong>MIT Flows</strong>
      <div className="flex justify-center items-center gap-5">
        <div className="flex flex-col gap-3">
          <a href="mit/authorize" className={`bg-yellow-500 ${btnStyle}`}>
            Authorize (Checkout)
          </a>
          <a href="mit/authorize-custom" className={`bg-yellow-600 ${btnStyle}`}>
            Authorize (Web SDK)
          </a>
        </div>
        <div className="flex flex-col gap-3">
          <a href="mit/charge" className={`bg-blue-500 ${btnStyle}`}>
            {" "}
            Charge (Checkout)
          </a>
          <a href="mit/charge-custom" className={`bg-blue-600 ${btnStyle}`}>
            {" "}
            Charge (Web SDK)
          </a>
        </div>
      </div>

      </div>
{/*       
      <div className="flex flex-col items-center gap-4 border border-gray-300 rounded-md mx-3 p-3">
      <strong>CIT Flows</strong>

      <div className="flex justify-center items-center gap-5">
        <div className="flex flex-col gap-3">
          <a href="cit/authorize" className={`bg-yellow-500 ${btnStyle}`}>
            Authorize (Checkout)
          </a>
          <a href="cit/authorize-custom" className={`bg-yellow-600 ${btnStyle}`}>
            Authorize (Web SDK)
          </a>
        </div>
        <div className="flex flex-col gap-3">
          <a href="cit/charge" className={`bg-blue-500 ${btnStyle}`}>
            {" "}
            Charge (Checkout)
          </a>
          <a href="cit/charge-custom" className={`bg-blue-600 ${btnStyle}`}>
            {" "}
            Charge (Web SDK)
          </a>
        </div>
      </div>
</div> */}
    </div>
  );
}
